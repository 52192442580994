import React from "react";
import { graphql, Link } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Helmet from "react-helmet";
import idFrom from "../utils/idFrom";

import greenLeafIcon from "../assets/images/leaf-green.png";
import orangeLeafIcon from "../assets/images/leaf-orange.png";
import shadowLeafIcon from "../assets/images/leaf-shadow.png";

import styles from "../assets/scss/pages.about.module.scss";

const baseIconProps = {
    shadowUrl: shadowLeafIcon,
    iconSize: [20, 50],
    iconAnchor: [11, 50],
    shadowSize: [30, 40],
    shadowAnchor: [3, 40],
    popupAnchor: [-3, -50],
};

const CENTER_OF_USA_POSITION = [39.8283, -98.5795];
const START_ZOOM = 3;
const VISITED_PLACES = [
    {
        title: "When in Dallas...",
        position: [32.7767, -96.797],
        url: "/when-in-dallas",
        stops: [
            {
                title: "The 6th Floor Museum",
                position: [32.7798, -96.8085],
            },
            {
                title: "The Dallas World Aquarium",
                position: [32.7834, -96.8052],
            },
            {
                title: "Dallas Museum of Art",
                position: [32.7876, -96.8009],
            },
            {
                title: "Deep Ellum Murals",
                position: [32.7827546, -96.7876891],
            },
            {
                title: "Deep Ellum Brewery",
                position: [32.7807, -96.7815],
            },
            {
                title: "Norma's Cafe",
                position: [32.7497632, -96.8434739],
            },
        ],
    },
    {
        title: "Who Goes to Minnesota?",
        position: [44.9778, -93.265],
        url: "/who-goes-to-minnesota",
        stops: [
            {
                title: "Minneapolis Institute of Art",
                position: [44.9585, -93.2742],
            },
            {
                title: "Walker Art Center",
                position: [44.9681, -93.2886],
            },
            {
                title: "Minnehaha Falls",
                position: [44.9153, -93.211],
            },
            {
                title: "Mall of America",
                position: [44.8549, -93.2422],
            },
        ],
    },
    {
        title: "South Bend, IN",
        position: [41.6764, -86.252],
        url: null,
        description: () => (
            <p style={{ margin: 0 }}>
                Papa Smurfs and Notre Dame football? Count me in.
            </p>
        ),
        stops: [
            {
                title: "Notre Dame University",
                position: [41.7056, -86.2353],
            },
            {
                title: "Taphouse On The Edge",
                position: [41.697311, -86.221797],
            },
        ],
    },
    {
        title: "North Utica, IL",
        position: [41.3414, -89.0097],
        url: null,
        description: () => (
            <p style={{ margin: 0 }}>
                It doesn't get much better than hiking and wine tasting.
            </p>
        ),
        stops: [
            {
                title: "August Hill Winery",
                position: [41.3406827, -89.0097268],
            },
            {
                title: "Starved Rock",
                position: [41.3212, -89.0002],
            },
        ],
    },
    {
        title: "Le Claire, IA",
        position: [41.5975, -90.3462],
        url: null,
        stops: [
            {
                title: "Antique Archaeology",
                position: [41.595696, -90.344447],
            },
        ],
    },
].map(place => {
    const placeId = idFrom.latLng(place.position);
    return {
        ...place,
        id: placeId,
        stops: place.stops.map(stop => ({
            ...stop,
            id: idFrom.latLng(stop.position),
            placeId,
        })),
    };
});
const STOPS = VISITED_PLACES.reduce((acc, curr) => [...acc, ...curr.stops], []);

let Map, TileLayer, Marker, Popup, L;
class AboutPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedPlaceId: -1,
        };
        this.map = React.createRef();
    }

    componentDidMount() {
        Map = require("react-leaflet").Map;
        TileLayer = require("react-leaflet").TileLayer;
        Marker = require("react-leaflet").Marker;
        Popup = require("react-leaflet").Popup;
        L = require("leaflet");
        this.placeIcon = new L.Icon({
            ...baseIconProps,
            iconUrl: greenLeafIcon,
        });
        this.stopIcon = new L.Icon({
            ...baseIconProps,
            iconUrl: orangeLeafIcon,
            iconSize: [10, 25],
            iconAnchor: [8, 25],
            shadowSize: [15, 25],
            shadowAnchor: [3, 25],
            popupAnchor: [-3, -25],
        });
        this.forceUpdate();
    }

    setSelectedPlace = selectedPlaceId => {
        const isAlreadySelected =
            this.state.selectedPlaceId === selectedPlaceId;
        if (isAlreadySelected) return;

        const selectedPlace = VISITED_PLACES.find(
            ({ id }) => id === selectedPlaceId
        );
        const latLngs = [
            selectedPlace.position,
            ...STOPS.filter(({ placeId }) => placeId === selectedPlaceId).map(
                ({ position }) => position
            ),
        ];
        const map = this.map.current.leafletElement;
        map.flyToBounds(latLngs, { padding: L.point(100, 100), duration: 0.5 });
        setTimeout(
            () =>
                map.flyTo(selectedPlace.position, map.getZoom(), {
                    duration: 0.25,
                }),
            500
        );
        this.setState({ selectedPlaceId });
    };

    setFullView = () => {
        const map = this.map.current.leafletElement;
        map.setView(CENTER_OF_USA_POSITION);
        map.setZoom(START_ZOOM);
        this.setState({ selectedPlaceId: -1 });
    };

    render() {
        const { selectedPlaceId } = this.state;
        const { data, location } = this.props;
        const siteTitle = data.site.siteMetadata.title;

        if (!Map) return null;

        return (
            <Layout location={location} title={siteTitle}>
                <Helmet>
                    <link
                        rel="stylesheet"
                        href="https://unpkg.com/leaflet@1.6.0/dist/leaflet.css"
                        integrity="sha512-xwE/Az9zrjBIphAcBb3F6JVqxf46+CDLwfLMHloNu6KEQCAWi6HcDUbeOfBIptF7tcCzusKFjFw2yuvEpDL9wQ=="
                        crossorigin=""
                    />
                </Helmet>
                <SEO title="About" />
                <p>
                    Gone With The Weekend is a blog detailing how we travel on a
                    budget with budgeted time. Our goal is to inspire you to see
                    the world in a way that makes sense for your schedule and
                    for your wallet.
                </p>
                <hr width="25%" style={{ margin: "20px auto" }} />
                <h2>What have we seen?</h2>
                <Map
                    center={CENTER_OF_USA_POSITION}
                    zoom={START_ZOOM}
                    style={{
                        height: 300,
                        borderRadius: 5,
                        boxShadow: "rgba(100, 100, 100, 0.5) 1px 3px 5px",
                    }}
                    ref={this.map}
                >
                    <TileLayer url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png" />
                    {VISITED_PLACES.map(
                        ({
                            title,
                            position,
                            id,
                            url,
                            description: Description,
                        }) => (
                            <Marker
                                onClick={() => this.setSelectedPlace(id)}
                                icon={this.placeIcon}
                                position={position}
                                key={id}
                            >
                                <Popup>
                                    {url ? (
                                        <Link to={url}>
                                            <h5 style={{ margin: 0 }}>
                                                {title}
                                            </h5>
                                        </Link>
                                    ) : (
                                        <h5 style={{ margin: 0 }}>{title}</h5>
                                    )}
                                    {Description && <Description />}
                                </Popup>
                            </Marker>
                        )
                    )}
                    {STOPS.map(
                        ({ position, title, id, placeId }) =>
                            selectedPlaceId === placeId && (
                                <Marker
                                    icon={this.stopIcon}
                                    position={position}
                                    key={id}
                                >
                                    <Popup>{title}</Popup>
                                </Marker>
                            )
                    )}
                    <button
                        className={styles.fullViewButton}
                        onClick={this.setFullView}
                    >
                        --
                    </button>
                </Map>
            </Layout>
        );
    }
}

export default AboutPage;

export const pageQuery = graphql`
    query {
        site {
            siteMetadata {
                title
            }
        }
    }
`;
