module.exports = {
    /**
     * Creates an id out of a positoin Array
     * @param {Array<Number>} position Array of [lat, lng]
     * @returns String
     */
    latLng: function(position) {
        return position.map(val => val.toString()).join("");
    },
};

